import React from 'react'

import Layout from '../components/layout'
import SEO from '../components/seo'
import Breadcrumb from '../components/breadcrumb'
import GenericPage from '../components/genericPage'

import content from '../json/demarche.json'

const DemarchePage = ({location}) => {
  return (
    <Layout simpleHeader={true} url="/notre-demarche">
      <SEO 
        title="La démarche : les marques sont-elles vertueuses sur le web ?"
        description="Notre démarche ? Mesurer l’impact utilisateur, environnemental et sociétal des sites des plus grandes marques françaises on-line."
        path="/notre-demarche" />

      <Breadcrumb url="/notre-demarche" label="Notre démarche" type="demarche" /> 

      <GenericPage slug="demarche" content={content} />
    </Layout>
  )
  }

export default DemarchePage

