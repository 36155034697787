import * as React from 'react'

import ContactSection from './contactSection'

const GenericPage = ({slug, content}) => {
  return (
    <>
      <div className="page has-border-top">
        <div className="fluid-grid">
          <div className="row">
            <div className="lg-col-1">
              <h1 className="title-section mb-10">{content.title}</h1>

              { content.intro &&
                <p className="paragraph" dangerouslySetInnerHTML={{ __html: content.intro }}></p>
              }
            </div>
          </div>
          <div className="row">
            <div className="w-full lg-col-offset-1 lg-col-2">
              { content.emphasis &&
                <p className="emphasis mt-8 lg:-mt-32 mb-8">{content.emphasis}</p>
              }
              <div className="paragraph" dangerouslySetInnerHTML={{ __html: content.content }}>
                
              </div>
            </div>
          </div>
        </div>
      </div>
      <ContactSection />
    </>
  )
}

export default GenericPage